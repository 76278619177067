export const GET_TASKS = "GET_TASKS";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK = "ADD_TASK";
export const GET_TASK = "GET_TASK";
export const UPDATE_TASK = "UPDATE_TASK";

export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const GET_COMPANY_USER = "GET_COMPANY_USER";
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";

export const GET_PROJECTS = "GET_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";


export const GET_CLIENTS = "GET_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const AUTH_ERROR = "AUTH_ERROR";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const TASK_STATUS_TYPES = {

  'planning': 'Planning',
  'in_progress': 'In Progress',
  'completed': 'Completed',
  'on_hold': 'On Hold',
};
